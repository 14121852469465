import { ModuleEvent, ModuleEventName } from './module-event';

export type ModuleEventListener = <EventName extends ModuleEventName>(event: ModuleEvent<EventName>) => unknown;

export const MODULE_EVENT_LISTENERS: ModuleEventListener[] = [];

export const removeEventListener = (listener: unknown) => {
  MODULE_EVENT_LISTENERS.splice(MODULE_EVENT_LISTENERS.indexOf(listener as ModuleEventListener), 1);
};

export const addEventListener = (listener: unknown | undefined | null) => {
  if (listener && typeof listener === 'function') {
    MODULE_EVENT_LISTENERS.push(listener as ModuleEventListener);
    return () => removeEventListener(listener);
  }
  return () => undefined;
};
