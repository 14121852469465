import bodyTypeTitleIcon from '../../../../assets/citroen/icon/bodyTypeTitleIcon.svg';
import check from '../../../../assets/citroen/icon/check.svg';
import checkCircle from '../../../../assets/citroen/icon/checkCircle.svg';
import closeIcon from '../../../../assets/citroen/icon/close_50x50.svg';
import co2Icon from '../../../../assets/citroen/icon/co2Icon.svg';
import consumptionIcon from '../../../../assets/citroen/icon/consumptionIcon.svg';
import emptyStar from '../../../../assets/citroen/icon/emptyStar.svg';
import fullStar from '../../../../assets/citroen/icon/fullStar.svg';
import fullStarRed from '../../../../assets/citroen/icon/fullStarRed.svg';
import gearIcon from '../../../../assets/citroen/icon/gearIcon.svg';
import fuelIcon from '../../../../assets/citroen/icon/fuelIcon.svg';
import halfStar from '../../../../assets/citroen/icon/halfStar.svg';
import halfStarRed from '../../../../assets/citroen/icon/halfStarRed.svg';
import licenseDateYear from '../../../../assets/citroen/icon/licenseDateYear.svg';
import mapPoiIconRed from '../../../../assets/citroen/icon/mapPoiIconRed.svg';
import mileage from '../../../../assets/citroen/icon/mileage.svg';
import motorTypeTitle from '../../../../assets/citroen/icon/motorTypeTitle.svg';
import phoneIconRed from '../../../../assets/citroen/icon/phoneIconRed.svg';
import placeholderSalesman from '../../../../assets/citroen/icon/placeholderSalesman.svg';
import citPoiActive from '../../../../assets/citroen/icon/poi/cit-poi-active.svg';
import citPoiDefault from '../../../../assets/citroen/icon/poi/cit-poi-default.svg';
import citPoiInactive from '../../../../assets/citroen/icon/poi/cit-poi-inactive.svg';
import powerIcon from '../../../../assets/citroen/icon/powerIcon.svg';
import priceIcon from '../../../../assets/citroen/icon/priceIcon.svg';
import citPartsStoreIcon from '../../../../assets/citroen/icon/services/parts.svg';
import citPassengerCarsSalesIcon from '../../../../assets/citroen/icon/services/passengercarsales.svg';
import citPassengerCarsServiceIcon from '../../../../assets/citroen/icon/services/service.svg';
import citUsedCarsIcon from '../../../../assets/citroen/icon/services/usedcars.svg';
import citPhone from '../../../../assets/citroen/icon/telephone.svg';
import autonomyIcon from '../../../../assets/citroen/icon/autonomyIcon.svg';
import electricConsumptionIcon from '../../../../assets/citroen/icon/electricConsumptionIcon.svg';
import carstorePowerIcon from '../../../../assets/citroen/icon/carstorePowerIcon.svg';
import citShowroomDetailsAdvisorFr from '../../../../assets/citroen/image/citroen-advisor-fr.png';
import citShowroomDetailsAdvisor from '../../../../assets/citroen/image/citroen-advisor.png';
import citShowroomDetailsAdvisorPng from '../../../../assets/citroen/image/cit-advisor.png';
import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import citLocationEmail from '../../../../assets/citroen/icon/location-email.svg';
import citLocationPhone from '../../../../assets/citroen/icon/location-phone.svg';
import citLocationMarker from '../../../../assets/citroen/icon/location-marker.svg';
import citLocationInfo from '../../../../assets/citroen/icon/infoIcon.svg';
import formDateInput from '../../../../assets/citroen/icon/form-date-input.svg';
import formTimeInput from '../../../../assets/citroen/icon/form-time-input.svg';
import prevArrow from '../../../../assets/citroen/icon/slider-prev.svg';
import nextArrow from '../../../../assets/citroen/icon/slider-next.svg';
import deleteIcon from '../../../../assets/citroen/icon/delete.svg';
import removeIcon from '../../../../assets/citroen/icon/remove-icon.svg';

import wltpCo2Icon from '../../../../assets/citroen/icon/wltp-co2.svg';
import wltpElectricIcon from '../../../../assets/citroen/icon/wltp-electric.svg';
import wltpGasIcon from '../../../../assets/citroen/icon/wltp-gas.svg';
import docExtension from '../../../../assets/citroen/icon/extension.svg';

export const citAssets = Object.freeze({
  bodyTypeTitleIcon,
  check,
  checkCircle,
  closeIcon,
  licenseDateYear,
  mileage,
  motorTypeTitle,
  noImagePlaceholder,
  priceIcon,
  powerIcon,
  co2Icon,
  consumptionIcon,
  gearIcon,
  fuelIcon,
  citPoiActive,
  citPoiInactive,
  citShowroomDetailsAdvisor,
  citShowroomDetailsAdvisorPng,
  citShowroomDetailsAdvisorFr,
  citPoiDefault,
  citPartsStoreIcon,
  citPhone,
  citUsedCarsIcon,
  citPassengerCarsSalesIcon,
  citPassengerCarsServiceIcon,
  mapPoiIconRed,
  phoneIconRed,
  fullStar,
  fullStarRed,
  halfStar,
  halfStarRed,
  emptyStar,
  placeholderSalesman,
  autonomyIcon,
  electricConsumptionIcon,
  carstorePowerIcon,
  citLocationEmail,
  citLocationPhone,
  citLocationMarker,
  citLocationInfo,
  formDateInput,
  formTimeInput,
  prevArrow,
  nextArrow,
  deleteIcon,
  wltpCo2Icon,
  wltpElectricIcon,
  wltpGasIcon,
  removeIcon,
  docExtension,
});
