let perfId = 0;

export const measurePerformance = (label: string) => {
  if (!('performance' in window)) {
    return () => 0;
  }

  const measureLabel = label + perfId++;
  const startLabel = measureLabel + '_START';
  const endLabel = measureLabel + '_END';
  window.performance.mark(startLabel);

  return () => {
    window.performance.mark(endLabel);
    window.performance.measure(measureLabel, startLabel, endLabel);
    return Math.round(window.performance.getEntriesByName(measureLabel)[0].duration);
  };
};
